<template>
  <div class="app-container user-container">
    <el-form inline :model="listQuery" label-width="120px">
      <el-form-item label="所属使馆：" v-if="isAdmin">
        <el-select v-model="listQuery.dipm_id" placeholder="请选择" @change="fetchData()" clearable>
          <el-option value='' label="未选择"></el-option>
          <el-option v-for="(item, index) in dipmList" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!-- 表格区域  -->
    <el-table
      :data="list"
      v-loading="loading"
      border
      fit
      hightlight-current-row
    >
      <el-table-column label="房间号" width="180" align="center" prop="room_id" />
      <el-table-column label="预约主题" min-width="150" align="center" prop="title" />
      <el-table-column label="申请人" width="150" align="center" prop="customer_name" />
      <el-table-column label="联系邮箱" width="200" align="center" prop="customer_email" />
      <el-table-column label="预约使馆" width="200" align="center" prop="dipm_name" v-if="isAdmin" />
      <el-table-column label="预约时间段" width="200" align="center">
        <template slot-scope="{ row }">
          <span>{{getTimeStr(row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" width="160" align="center" prop="created_time" />
      <el-table-column width="180" align="center" label="操作">
        <template slot-scope="{ row }">
          <el-link type="success" @click="doAdd(row)">编辑</el-link>
          <el-divider direction="vertical"></el-divider>
          <el-link type="success" @click="view(row)">查看录制</el-link>
          <el-divider direction="vertical"></el-divider>
          <el-link type="primary" @click="entry(row)" :disabled="row.status != 1">进入</el-link>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="listQuery.page"
      :page-size="listQuery.page_size"
      :total="total"
      style="padding: 32px 0;"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100, 150, 200]"
      @current-change="fetchData"
    />

    <el-dialog
      title="修改预约"
      :visible.sync="dialogVisible"
      :key="dialogIndex"
      @closed="dialogIndex++"
    >
      <el-form label-width="120px">
        <el-form-item label="申请主题：">
          <span>{{postData.title}}</span>
        </el-form-item>
        <el-form-item label="申请人：">
          <span>{{postData.customer_name}}</span>
        </el-form-item>
        <el-form-item label="联系邮箱：">
          <span>{{postData.customer_email}}</span>
        </el-form-item>
        <el-form-item label="预约时间范围：">
          <el-date-picker
            v-model="range"
            type="datetimerange"
            range-separator="至"
            :clearable="false"
            :editable="false"
            start-placeholder="开始日期/时间"
            end-placeholder="结束日期/时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="预约状态：">
          <el-radio-group v-model="postData.status">
            <el-radio :label="0">已取消</el-radio>
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">已完成</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doSure" :loading="uploading">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 录制列表 -->
    <el-dialog
      title="录制列表"
      :visible.sync="dialogVisible2"
      :key="dialogIndex2"
      @closed="dialogIndex2++"
    >
      <el-table
        :data="videos"
        v-loading="fetching"
        border
        fit
        hightlight-current-row
      >
        <el-table-column label="文件名" min-width="100px" prop="Key" />
        <el-table-column label="文件大小" prop="Key" align="center" width="100px">
          <template slot-scope="{ row }">
            <span>{{row.Size | numberFormatter}}</span>
          </template>
        </el-table-column>
        <el-table-column label="更新时间" prop="Key" width="180px" align="center">
          <template slot-scope="{ row }">
            <span>{{row.LastModified | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100px" align="center">
          <template slot-scope="{ row }">
            <el-link type="success" @click="download(row)">下载</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script type="text/javascript">
import { mapGetters, mapState } from 'vuex'
import room from '@/api/room'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      listQuery: {},
      list: [],
      loading: false,
      dialogIndex: 0,
      dialogVisible: false,
      dialogIndex2: 100,
      dialogVisible2: false,
      fetching: false,
      uploading: false,
      total: 0,
      postData: {
        start: '',
        end: '',
      },
      videos: []
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState(['user']),
    range: {
      get() {
        if (this.postData.start && this.postData.end) {
          return [new Date(this.postData.start), new Date(this.postData.end)]
        }
        return null
      },
      set(val) {
        this.postData.start = val[0]
        this.postData.end = val[1]
      }
    }
  },
  created() {
    if (!this.isAdmin) {
      this.listQuery.dipm_id = this.user.diplomaticDTO.id
    }
    this.fetchData()
  },
  methods: {
    download(row) {
      const cb = this.$loading()
      room.getVideoUrl(row.Key)
      .then(res => {
        cb.close()
        window.open(res)
      })
      .catch(cb.close)
    },
    view(row) {
      this.videos = []
      this.dialogVisible2 = true
      this.fetching = true
      room.getVideos(row.room_id)
      .then(res => {
        this.videos = res || []
        this.fetching = false
      })
      .catch(() => {
        this.fetching = false
      })
    },
    fetchData(page = 1) {
      this.listQuery.page = page
      room.getList(this.listQuery)
      .then(res => {
        this.list = res.list || []
        this.total = res.total
      })
    },
    getTimeStr(row) {
      if (!row.start || !row.end) return ''
      return dayjs(row.start).format('YYYY-MM-DD HH:mm ~ ') + dayjs(row.end).format('HH:mm')
    },
    entry(row) {
      this.$router.push(`/video/chat?room_id=${row.room_id}`)
    },
    doSure() {
      this.uploading = true
      room.update(this.postData.room_id, this.postData)
      .then(() => {
        this.$message.success('更新成功')
        this.dialogVisible = false
        this.uploading = false
      })
      .catch(() => {
        this.uploading = false
      })
    },
    doAdd(row) {
      this.postData = {...row}
      this.dialogVisible = true
    }
  }
}
</script>
